.error-avatar {
  width: 100% !important;
  height: auto !important;
}

.error-code {
  color: #434e59 !important;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.error-title {
  color: #434e59 !important;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.error-description {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}

@white-color: #ffffff;@primary-color: #75FA9B;@text-color: #333;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #cccccc;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #F2F4F8;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;@error-color: #f5222d;@screen-sm: 600px;@screen-md-min: 600px;@text-color-secondary: #fff;@primary-6: black;