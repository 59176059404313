.app-splash-screen {
  .flower {
    width: 15px;
    height: 15px;
    position: fixed;
    top: -10%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
    z-index: 10;

    .cls-1 {
      fill: #ecbe22;
    }
    .cls-2 {
      fill: #ed8d3e;
    }
  }

  .flower {
    -webkit-animation-name: flowers-fall, flowers-no-shake;
    -webkit-animation-duration: 12s, 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: flowers-fall, flowers-no-shake;
    animation-duration: 12s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;
  }

  .flower:nth-of-type(1),
  .flower:nth-of-type(2),
  .flower:nth-of-type(15),
  .flower:nth-of-type(16) {
    -webkit-animation-name: flowers-fall, flowers-shake;
    animation-name: flowers-fall, flowers-shake;
  }

  .flower:nth-of-type(0) {
    left: 0%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .flower:nth-of-type(1) {
    width: 10px;
    height: 10px;
    left: 5%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .flower:nth-of-type(2) {
    width: 20px;
    height: 20px;
    left: 5.2%;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  .flower:nth-of-type(3) {
    width: 8px;
    height: 8px;
    left: 12%;
    -webkit-animation-delay: 6.15s;
    animation-delay: 6.15s;
  }

  .flower:nth-of-type(4) {
    left: 12.5%;
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
  }

  .flower:nth-of-type(5) {
    width: 20px;
    height: 20px;
    left: 20%;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }

  .flower:nth-of-type(6) {
    left: 30%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  .flower:nth-of-type(7) {
    left: 38%;
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
  }

  .flower:nth-of-type(8) {
    width: 20px;
    height: 20px;
    left: 40%;
    -webkit-animation-delay: 9.5s;
    animation-delay: 9.5s;
  }

  .flower:nth-of-type(9) {
    left: 45%;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }

  .flower:nth-of-type(10) {
    width: 20px;
    height: 20px;
    left: 50%;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  .flower:nth-of-type(11) {
    left: 60%;
    -webkit-animation-delay: 7.2s;
    animation-delay: 7.2s;
  }

  .flower:nth-of-type(12) {
    width: 20px;
    height: 20px;
    left: 62%;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
  }

  .flower:nth-of-type(13) {
    width: 20px;
    height: 20px;
    left: 70%;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }

  .flower:nth-of-type(14) {
    left: 78%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  .flower:nth-of-type(15) {
    left: 92%;
    -webkit-animation-delay: 7.2s;
    animation-delay: 7.2s;
  }

  .flower:nth-of-type(16) {
    width: 20px;
    height: 20px;
    left: 90%;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
  }

  .flower:nth-of-type(17) {
    width: 10px;
    height: 10px;
    left: 83%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .flower:nth-of-type(18) {
    width: 20px;
    height: 20px;
    left: 85%;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }

  .flower:nth-of-type(19) {
    width: 25px;
    height: 25px;
    left: 3%;
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
  }

  .flower:nth-of-type(20) {
    width: 25px;
    height: 25px;
    left: 88%;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }

  .flower:nth-of-type(21) {
    width: 25px;
    height: 25px;
    left: 24%;
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
  }

  .flower:nth-of-type(22) {
    width: 25px;
    height: 25px;
    left: 66%;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }

  .flower:nth-of-type(23) {
    width: 10px;
    height: 10px;
    left: 17%;
    -webkit-animation-delay: 11s;
    animation-delay: 11s;
  }

  .flower:nth-of-type(24) {
    width: 8px;
    height: 8px;
    left: 80%;
    -webkit-animation-delay: 10s;
    animation-delay: 10s;
  }

  @-webkit-keyframes flowers-fall {
    0% {
      top: -10%;
    }
    100% {
      top: 100%;
    }
  }

  @-webkit-keyframes flowers-shake {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
    }
    50% {
      -webkit-transform: translateX(40px) rotate(180deg);
      transform: translateX(50px) rotate(180deg);
    }
    100% {
      -webkit-transform: translateX(0) rotate(360deg);
      transform: translateX(0) rotate(360deg);
    }
  }

  @-webkit-keyframes flowers-no-shake {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
    }
    50% {
      -webkit-transform: translateX(0) rotate(180deg);
      transform: translateX(0) rotate(180deg);
    }
    100% {
      -webkit-transform: translateX(0) rotate(360deg);
      transform: translateX(0) rotate(360deg);
    }
  }

  @keyframes flowers-fall {
    0% {
      top: -10%;
    }
    100% {
      top: 100%;
    }
  }

  @keyframes flowers-shake {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
    }
    50% {
      -webkit-transform: translateX(40px) rotate(180deg);
      transform: translateX(50px) rotate(180deg);
    }
    100% {
      -webkit-transform: translateX(0) rotate(360deg);
      transform: translateX(0) rotate(360deg);
    }
  }

  @keyframes flowers-no-shake {
    0% {
      -webkit-transform: translateX(0) rotate(0deg);
      transform: translateX(0) rotate(0deg);
    }
    50% {
      -webkit-transform: translateX(0) rotate(180deg);
      transform: translateX(0) rotate(180deg);
    }
    100% {
      -webkit-transform: translateX(0) rotate(360deg);
      transform: translateX(0) rotate(360deg);
    }
  }

  @media screen and (min-width: 1400px) {
    [id*='tet-parallax__'] {
      display: block !important;
    }
  }

  #tet-parallax__left {
    width: calc((100% - 1200px) / 2);
    text-align: right;
    position: fixed;
    top: 94px;
    left: 0;
  }

  #tet-parallax__right {
    width: calc((100% - 1200px) / 2);
    text-align: left;
    position: fixed;
    top: 94px;
    right: 0;
  }

  [id*='tet-parallax__'] > #tet-parallax > img {
    width: 100%;
    margin-top: -10%;
    z-index: -1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
  }

  #tet-parallax__left > #tet-parallax > img {
    max-width: 400px;
  }

  #tet-parallax__right > #tet-parallax > img {
    max-width: 460px;
  }

  [id*='tet-parallax__'] > #tet-parallax > a > img {
    width: 100%;
    max-width: 240px;
    margin-top: 10%;
    padding: 0 10px;
  }

  #tet-parallax__left > #tet-parallax > a > img {
    width: 96.5%;
    max-width: 237px;
    margin-top: 5.5%;
  }

  #tet-parallax__right > #tet-parallax > a > img {
    margin-top: 6%;
  }

  @media screen and (min-width: 1921px) {
    [id*='tet-parallax__'] > #tet-parallax > img {
      margin-top: -3%;
    }
  }

  #fireworks > img {
    -webkit-animation-name: firework;
    -webkit-animation-duration: 12s, 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: firework;
    animation-duration: 3s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;
  }

  @keyframes firework {
    0% {
      width: 0px;
    }
    80% {
      width: 80px;
    }
    100% {
      width: 80px;
    }
  }

  @-webkit-keyframes firework {
    0% {
      width: 0px;
    }
    80% {
      width: 80px;
    }
    100% {
      width: 80px;
    }
  }
}
