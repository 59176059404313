body {
  font-size: 16px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
  margin: 0;
}
.cke_dialog_tab[title='Upload'],
.cke_dialog_tab[title='Link'] {
  display: none !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html {
  overflow: hidden;
}
#root {
  height: 100vh !important;
  overflow: hidden;
  /* color: rgb(7, 143, 233) */
}


