body {
  overflow-y: auto;
  margin: 0;
}

.ant-layout-header {
  z-index: 3 !important;
}

/* Page Scroll */
.wrap-page-scroll {
  height: calc(100% - 72px);
  width: 100%;
  position: absolute;
  .page-scrollable {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    > .ant-card {
      border-radius: 40px !important;
      box-shadow: none;
    }
  }
}

.ant-layout-sider-children {
  margin-left: -8px;
}

.wrap-page-footer {
  position: absolute;
  bottom: 0px;
  @media only screen and (max-width: 600px) {
    bottom: 48px !important;
  }
  width: calc(100% - 32px);
  // width: calc(100vw - 350px);
  height: 60px !important;
  margin-top: 16px;
  z-index: 10;
  .ant-card {
    background: #f2ebd9;
    backdrop-filter: blur(8px);
    border-radius: 16px;
    .ant-card-body {
      padding: 8px;
    }
  }
}
.wrap-page-footer-fixed {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: calc(100vw - 370px);
  height: 60px;
  margin-top: 16px;
  z-index: 10;
  .ant-card {
    background: #f2ebd9;
    backdrop-filter: blur(8px);
    border-radius: 16px;
    .ant-card-body {
      padding: 8px;
    }
  }
}

.line-clamp(@numLines : 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @numLines;
  -webkit-box-orient: vertical;
}

.text-truncate-1 {
  word-break: break-word;
  .line-clamp(1);
}

.text-truncate-2 {
  word-break: break-word;
  .line-clamp(2);
}
.text-truncate-3 {
  word-break: break-word;
  .line-clamp(3);
}
.text-truncate-5 {
  word-break: break-word;
  .line-clamp(5);
}
.center {
  &:extend(.flex);
  &:extend(.center-content);
}
.pointer {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.auto {
    flex: auto;
  }
  &.center-items {
    align-items: center;
  }
  &.center-content {
    justify-content: center;
  }
  &.left-content {
    justify-content: flex-start;
  }
  &.right-content {
    justify-content: flex-end;
  }
  &.between-content {
    justify-content: space-between;
  }
}
.flex-1 {
  flex: 1;
}
.inline-flex {
  display: inline-flex;
}

// Color
.error {
  color: @error-color;
}
